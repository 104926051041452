<template>
  <component :is="headingType" class="title">
    <slot />
  </component>
</template>

<script>
export default {
  name: "Title",
  props: {
    headingType: {
      type: String,
      default: "h2",
    },
  },
};
</script>

<style scoped lang="scss">
/* Title */

h2.title {
  font-size: 1.85rem;
  font-weight: 900;
}

h3.title {
  font-size: 1.5rem;
  margin-bottom: 0.5em;
}

@media only screen and (min-width: 425px) {
  h2.title {
    font-size: 2.2rem;
  }
  h3.title {
    font-size: 1.7rem;
  }
}

@media only screen and (min-width: 1024px) {
  h2.title {
    font-size: 2.25rem;
  }
  h3.title {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 1250px) {
  h2.title {
    font-size: 2.5rem;
  }
  h3.title {
    font-size: 2rem;
  }
}
</style>
