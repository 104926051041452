<template>
  <header :class="{'header': true}">
    <Wrap>
      <nav :class="{'header__nav': true,  'header__nav--open': openMenu}">
        <a
        @click="toggleMenu"
          data-scroll
          href="#wat-doen-wij"
          class="main-navigation-item"
          title="Wat doen wij?"
          >Wat doen wij?</a
        >
        <a
        @click="toggleMenu"
          data-scroll
          href="#wie-zoeken-wij"
          class="main-navigation-item"
          title="Wie zoeken wij?"
          >Wie zoeken wij?</a
        >
        <a
        @click="toggleMenu"
          data-scroll
          href="#wat-bieden-wij"
          class="main-navigation-item"
          title="Wat bieden wij?"
          >Wat bieden wij?</a
        >
        <a
        @click="toggleMenu"
          data-scroll
          href="#interesse"
          class="main-navigation-item"
          title="Interesse?"
          >Interesse?</a
        >
      </nav>
    </Wrap>
    <button :class="{'hamburger': true, 'hamburger--active': openMenu}" name="menu" @click="toggleMenu">
      <span class="hamburger__line hamburger__top-line"></span>
      <span class="hamburger__line hamburger__middle-line"></span>
      <span class="hamburger__line hamburger__bottom-line"></span>
    </button>
  </header>
</template>

<script>
export default {
  name: "Header",
  props: {
    msg: String,
  },
  data() {
    return {
      openMenu: false,
    }
  },
  methods: {
    toggleMenu(){
      this.openMenu = !this.openMenu;
      this.$emit('hamburger-click');
    }
  }
};
</script>

<style scoped lang="scss">
@import "../assets/mixins.scss";

/* Header */
.header {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  display: inline;

  &:not(.header--open){
    mix-blend-mode: difference;
  }
  &__nav {
    display: flex;
    gap: 2em;
    flex-direction: column;
    position: absolute;
    right: 0;
    padding: 6em;
    z-index: 1;
    border-radius: 0% 0 0% 50%;
    top: 0;
    transform-origin: top right;
    transition: transform 300ms ease-in-out;


    a {
      transition: opacity 300ms ease-in-out;
      transition-delay: 200ms;
      opacity: 0;
      @include mq(lg) {
        opacity: 1;
      }
    }

    transform: scale(0);

    &:after {
      content: "";
      position: absolute;
      background-color: white;
      border-radius: 50%;
      width: 200px;
      height: 200px;
      top: 0;
      z-index: -1;
      right: 0;
      left: 0;
      bottom: 0;
      transform: scale(0) translateX(60%) translateY(-4%);
      transform-origin: top right;
      transition: transform 300ms ease-in-out;
      backdrop-filter: blur(5px);
    }



    &--open {
      transform: scale(1);
      a {
        opacity: 1;
        pointer-events: unset;
      }

      &:after {
        transform: scale(2.5)  translateX(50%) translateY(-30%);
      }
    }

    @include mq(lg) {
      transform: unset;
      position: unset;
      top: unset;
      right: unset;
      flex-direction: row;
      gap: 2em;
      width: 100%;
      justify-content: flex-end;
      padding: 3em 0;
      color: white;
      font-size: 0.8rem;
      font-weight: bold;
      background-color: unset;

      &:after {
        content: unset;
      }

    }
  }
}
.hamburger {
  @include mq(lg) {
    display: none;
  }
  width: 40px;
  height: 30px;
  position: absolute;
  right: 10%;
  z-index: 100;
  top: 50%;
  transform: translateY(-50%);
  
  cursor: pointer;
  &__line {
    display: block;
    width: 100%;
    height: 3px;
    mix-blend-mode: difference;
    background-color: white;
    position: absolute;
    top: 0;
    right: 0;
    transition: background-color 300ms ease-in-out;

    &:nth-child(2){
      top: 50%;
      width: 50%;
      transition: width 300ms ease-in-out;
      transform: translateY((-50%));
    }

    &:last-child {
      top: unset;
      bottom: 0;
    }
  }

  &--active {
    .hamburger__line{
      background-color: black;
      &:nth-child(2){
        width: 100%;
      }

    }
    
  }
}
</style>
