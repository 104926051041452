<template>
  <div :class="{ text: true, 'text--white': white }">
    <Wrap>
      <div class="text__heading">
        <Title>
          {{ heading }}
        </Title>
      </div>
      <div :class="{ text__body: true, 'text__body--small': small }">
        <slot />
      </div>
      <div class="text__footer">
        <Title>
          {{ footer }}
        </Title>
      </div>
    </Wrap>
  </div>
</template>

<script>
export default {
  name: "Text",
  props: {
    white: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: String,
      default: "Wie zoeken wij?",
    },
    small: {
      type: Boolean,
      default: true,
    },
    footer: {
      type: String,
      default: "Iemand met passie voor het web en de techniek erachter.",
    },
  },
};
</script>

<style scoped lang="scss">
.text {
  background-color: black;
  color: white;
  &:not(:last-of-type){
    scroll-snap-align: start;
  }

  &--white {
    background-color:white;
    color:  black;
  }

  & > div:first-child {
    min-height: 100vh;
    height: 100%;
    padding: 4em 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__body {
    font-size: 1.5em;

    &--small {
      margin: 0 auto;
      @media only screen and (min-width: 600px) {
        width: 70%;
      }
    }
  }

  &__footer {
    align-self: flex-end;
    text-align: right;
  }
}
</style>
