<template>
  <div
    :class="{ photo: true, 'photo--fixed': fixed }"
    :style="{ 'background-image': `url(${require('@/assets/img/' + src)})` }"
  ></div>
</template>

<script>
export default {
  name: "Photo",
  props: {
    src: String,
    fixed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/mixins.scss";

.photo {
  width: 100%;
  height: 50vh;

  @include mq(lg) {
    height: 100vh;
  }

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  &--fixed {
    @include mq(lg) {
      background-attachment: fixed;
    }
  }
}
</style>
