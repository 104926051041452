<template>
  <div class="not-hiring">
    <svg
      id="tnt-logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 203.47 63.29"
      fill="white"
      width="200px"
    >
      <g id="tnt">
        <polygon
          points="9.7 62.38 4.93 62.38 4.93 42.08 0 42.08 0 37.92 14.63 37.92 14.63 42.08 9.7 42.08 9.7 62.38"
        />
        <path
          d="m23.16,57.23l-2.01,5.16h-5.03l9.54-25.1h3.7l9.28,25.1h-5.09l-1.88-5.16h-8.5Zm4.12-12.78h-.06l-2.7,8.89h5.81l-3.05-8.89Z"
        />
        <polygon
          points="47.95 58.23 54.69 58.23 54.69 62.38 43.18 62.38 43.18 37.93 47.95 37.93 47.95 58.23"
        />
        <polygon
          points="65.01 58.23 71.75 58.23 71.75 62.38 60.24 62.38 60.24 37.93 65.01 37.93 65.01 58.23"
        />
        <rect x="77.31" y="37.92" width="4.77" height="24.46" />
        <polygon
          points="94.34 42.08 94.34 47.46 101.93 47.46 101.93 51.61 94.34 51.61 94.34 58.23 102.22 58.23 102.22 62.38 89.57 62.38 89.57 37.93 102.22 37.93 102.22 42.08 94.34 42.08"
        />
        <path
          d="m108.94,37.92h4.76v13.68c0,3.02.26,7.27,4.32,7.27s4.31-4.25,4.31-7.27v-13.68h4.76v14.63c0,5.96-2.53,10.48-9.08,10.48s-9.08-4.52-9.08-10.48v-14.63h.01Z"
        />
        <polygon
          points="9.7 25.48 4.93 25.48 4.93 5.18 0 5.18 0 1.03 14.63 1.03 14.63 5.18 9.7 5.18 9.7 25.48"
        />
        <path
          d="m23.16,20.33l-2.01,5.16h-5.03L25.66.38h3.7l9.28,25.1h-5.09l-1.88-5.16h-8.5Zm4.12-12.78h-.06l-2.7,8.88h5.81l-3.05-8.88Z"
        />
        <polygon
          points="47.95 21.33 54.69 21.33 54.69 25.48 43.18 25.48 43.18 1.03 47.95 1.03 47.95 21.33"
        />
        <polygon
          points="65.01 21.33 71.75 21.33 71.75 25.48 60.24 25.48 60.24 1.03 65.01 1.03 65.01 21.33"
        />
        <rect x="77.31" y="1.03" width="4.77" height="24.45" />
        <polygon
          points="94.34 5.18 94.34 10.57 101.93 10.57 101.93 14.71 94.34 14.71 94.34 21.33 102.22 21.33 102.22 25.48 89.57 25.48 89.57 1.03 102.22 1.03 102.22 5.18 94.34 5.18"
        />
        <path
          d="m108.94,1.03h4.76v13.68c0,3.02.26,7.27,4.32,7.27s4.31-4.25,4.31-7.27V1.03h4.76v14.62c0,5.97-2.53,10.48-9.08,10.48s-9.08-4.52-9.08-10.48V1.03h.01Z"
        />
        <path
          d="m187.94,44.16l9.61-11.28-6.27-6.48-9.19,10.65-16.41-19.85c5.96-4.35,12.78-9.66,14.05-17.19h-9.36c-1.31,4.2-5.63,6.93-8.97,9.56l-1.26.84-2.61-3.34c-1.72-2.2-3.51-4.4-4.29-7.06h-9.49c.61,6.01,4.42,11.31,8.55,15.62l-4.92,3.56c-7.52,5.43-13.8,10.87-13.8,20.9,0,13.58,10.98,23.2,24.15,23.2,9.29,0,17.65-5.34,23.61-12.34l10.01,12.31h12.11l-15.53-19.1Zm-30.51,10.35c-7.52,0-14.62-7-14.62-14.52s8.89-12.84,14.31-16.61l.94-.62,17.46,21.31c-4.92,4.92-10.78,10.45-18.09,10.45Z"
        />
      </g>
    </svg>
    <div>Er zijn momenteel geen openstaande vacatures.</div>
    <div>
      <a href="http://tnt.be">Terug naar tnt.be</a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.not-hiring {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 2em;
  flex-direction: column;
  gap: 1em;
  text-align: center;

  div:first-child {
    font-size: 25px;
  }
}
</style>