<template>
  <div class="hero">
    <!-- <div class="hero__logo">
      <svg
        id="logo"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 993.18 305"
        width="150px"
        fill="white"
      >
        <g id="u">
          <path
            d="m521.27,4.36h23.23v62.44c0,14.52,1.45,34.85,20.33,34.85s20.33-20.33,20.33-34.85V4.36h23.23v66.79c0,29.04-11.62,50.82-43.56,50.82s-43.56-21.78-43.56-50.82V4.36Z"
          />
          <path
            d="m521.27,181.5h23.23v62.15c0,14.52,1.45,34.85,20.33,34.85s20.33-20.33,20.33-34.85v-62.15h23.23v66.5c0,29.04-11.62,50.82-43.56,50.82s-43.56-21.78-43.56-50.82v-66.5Z"
          />
        </g>
        <g id="e">
          <polygon
            points="429.8 181.5 429.8 299.12 489.33 299.12 489.33 278.79 451.58 278.79 451.58 246.84 487.88 246.84 487.88 227.97 451.58 227.97 451.58 201.83 489.33 201.83 489.33 181.5 429.8 181.5"
          />
          <polygon
            points="429.8 4.36 429.8 121.97 489.33 121.97 489.33 101.64 451.58 101.64 451.58 71.15 487.88 71.15 487.88 50.82 451.58 50.82 451.58 24.68 489.33 24.68 489.33 4.36 429.8 4.36"
          />
        </g>
        <g id="i">
          <rect x="370.26" y="181.5" width="23.23" height="117.61" />
          <rect x="370.26" y="4.36" width="23.23" height="117.61" />
        </g>
        <g id="l2">
          <polygon
            points="288.95 181.5 288.95 299.12 344.13 299.12 344.13 278.79 310.73 278.79 310.73 181.5 288.95 181.5"
          />
          <polygon
            points="288.95 4.36 288.95 121.97 344.13 121.97 344.13 101.64 310.73 101.64 310.73 4.36 288.95 4.36"
          />
        </g>
        <g id="l1">
          <polygon
            points="206.19 181.5 206.19 299.12 261.36 299.12 261.36 278.79 229.42 278.79 229.42 181.5 206.19 181.5"
          />
          <polygon
            points="206.19 4.36 206.19 121.97 261.36 121.97 261.36 101.64 229.42 101.64 229.42 4.36 206.19 4.36"
          />
        </g>
        <g id="a">
          <path
            d="m130.68,215.48h0l14.52,41.09h-27.59l13.07-41.09Zm-7.26-33.98l-45.01,117.61h23.23l10.16-24.1h40.66l8.71,24.1h24.68l-45.16-117.61h-17.28Z"
          />
          <path
            d="m130.68,38.33h0l14.52,41.09h-27.15l12.78-41.09h-.15Zm-7.11-33.98l-45.3,117.61h24.1l9.87-24.1h39.64l8.57,24.1h23.81L140.7,4.36h-17.13Z"
          />
        </g>
        <g id="t">
          <polygon
            points="0 4.36 0 24.68 23.23 24.68 23.23 121.97 46.46 121.97 46.46 24.68 69.7 24.68 69.7 4.36 0 4.36"
          />
          <polygon
            points="0 181.5 0 201.83 23.23 201.83 23.23 299.12 46.46 299.12 46.46 201.83 69.7 201.83 69.7 181.5 0 181.5"
          />
        </g>
        <g id="ampersand">
          <path
            d="m771.17,261.36c-37.86-1.49-68.21-31.84-69.7-69.7,0-36.3,42.11-60.98,68.24-79.86l4.36-2.9,84.22,101.64c-23.23,24.68-50.82,50.82-87.12,50.82m146.65-49.37l46.46-53.72-30.49-30.49-43.56,50.82-78.55-95.83c29.04-20.33,60.98-46.46,66.79-82.77h-45.01c-5.81,20.33-27.59,33.4-43.56,46.46l-5.81,4.36-11.62-17.42c-9.14-9.51-16.08-20.91-20.33-33.4h-45.01c2.9,29.04,21.78,53.72,40.66,75.5l-23.23,17.42c-36.3,26.14-66.79,52.27-66.79,100.19.04,61.83,50.19,111.92,112.02,111.88,1.38,0,2.76-.03,4.15-.08,45.01,0,84.22-26.14,113.26-59.53l47.92,59.53h58.08l-75.36-92.93Z"
          />
        </g>
      </svg>
    </div> -->
    <img class="hero__photo" :src="require('@/assets/img/' + photo)" />
    <div class="hero__description">
      <span class="hero__description--subtitle">Vacature:</span>
      <span class="hero__description--subject">Full stack web developer</span>
      <span class="hero__description--subtitle">( Junior / Senior )</span>
      <div class="hero__description--caption">
        Do you code in style with speed and accuracy?<br>
        Want to play? Level up! Pursue new horizons.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
  props: {
    photo: String,
  },
};
</script>

<style scoped lang="scss">
@import "../assets/mixins.scss";

.hero {
  display: flex;
  position: relative;
  flex-direction: column-reverse;
  scroll-snap-align: start;

  @include mq(lg) {
    flex-direction: row;
    height: 100vh;
  }

  &__logo {
    position: absolute;
    top: 2em;
    left: 2em;
    @include mq(lg) {
      left: 5em;
    }
  }

  &__photo,
  &__description {
    width: 100%;
    min-height: 50vh;
    @include mq(lg) {
      width: 50%;
      height: 100vh;
    }
  }

  &__photo {
    object-fit: cover;
    object-position: center;
    // -webkit-mask-image: -webkit-gradient(linear, left 65%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
   -webkit-mask: 
    linear-gradient(to top,  transparent 10%, #fff 25% 95%, transparent 95%),
    linear-gradient(to bottom, transparent 1%, #fff 15% 90%, transparent 95%);
  -webkit-mask-size:110% 110%;
  -webkit-mask-position:center;
  -webkit-mask-repeat:no-repeat;
  -webkit-mask-composite: source-in;
  }

  &__description {
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    font-size: 5vw;
    line-height: 1.25;
    font-weight: 800;
    width: 85%;
    margin: 0 auto;
    justify-content: center;
    padding-top: 4em;

    @include mq(sm) {
      font-size: 5vw;
    }

    @include mq(lg) {
      padding: 20% 7%;
      font-size: 2.5rem;
    }

    @include mq(lg) {
      font-size: 3.25rem;
    }

    &--subject {
      font-size: 2em;
      @include mq(lg) {
        font-size: unset;
      }
      text-transform: uppercase;
    }

    &--caption {
      font-size: 1rem;
      margin-top: 1em;
    }

    &--subtitle {
      @include mq(lg) {
        font-size: 1.75rem;
      }
      @include mq("2xl") {
        font-size: 2rem;
      }
    }
  }
}
</style>
