<template>
  <div class="wrap">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Wrap',
}
</script>

<style scoped lang="scss">
.wrap
{
    width: 85%;
    margin: 0 auto;
}

@media only screen and (min-width: 1024px)
{
    .wrap
    {
        width: 92%;
    }
}

@media only screen and (min-width: 1200px)
{
    .wrap
    {
        width: 85%;
    }
}
</style>
