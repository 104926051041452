import { createApp } from 'vue'
import App from './App.vue'
import  './assets/reset.css'
import  './assets/general.scss'
import Wrap from '@/components/Wrap.vue';
import Title from '@/components/Title.vue';
import VueGtag from 'vue-gtag'


const app = createApp(App)
app.component("Wrap", Wrap);
app.component("Title", Title);
app.use(VueGtag, {
    config: { id: 'G-XXXXXXXXXX' }
})
app.mount('#app')