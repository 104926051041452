<template>
  <div class="columns">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Column",
};
</script>

<style scoped lang="scss">
@import "../assets/mixins.scss";

.columns {
  
  @include mq(lg) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    gap: 3em;
  }

}
</style>
